<template>
  <div>
    <swiper-container 
      ref="labelSwiperRef"
      class="labelSwiper-container"
      init="false"
    >
      <swiper-slide 
        v-for="(item,index) in config"
        :key="index"
      >
        <div class="item-container">
          <QuickShipTag
            v-if="item.quickship && qsMallList.last_quickship_tag"
            :cartQsTagInfo="{
              ...quickShipInfoTag.qsDataNoLabelProps,
              themVarText: { color: '#fff' },
              themVarIcon: { color: '#fff' }
            }"
            :qsMallList="qsMallList"
            :lastQsTime="true"
          />
          <template v-else>
            <Icon
              :name="item.icon"
              size="15px"
              color="#fff"
            />
            <span :class="['text', item.quickship && 'font-ita']">{{ item.text }}</span>
          </template>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>
<script setup>
import { ref, nextTick, watchEffect } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import QuickShipTag from 'public/src/pages/cartNew/components/list/item/QuickShipTag.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/page_tpls/hook/useQuickShip.js'

const { quickShipInfoTag, qsMallList } = useGetGuickShip()

typeof window !== 'undefined' && register()

const labelSwiperRef = ref(null)
let numIndex = ref(0)
const props = defineProps({
  config: {
    type: Array,
    default: () => ([])
  }
})
const initSwiper = () => {
  nextTick(() => {
    labelSwiperRef.value && Object.assign(labelSwiperRef.value, {
      direction: 'vertical',
      modules: [Autoplay],
      autoplay: {
        delay: 250,
        disableOnInteraction: false
      },
      noSwipingClass: 'item-container',
      loop: true,
      observer: true,
      observeParents: true,
      noSwiping: true,
      on: {
        slideChangeTransitionEnd: async (swiper) => {
          numIndex.value++
          if(numIndex.value == props.config.length){
            swiper.autoplay.stop()
            numIndex.value = 0
            await timerAutoPlay(swiper)
          }
        }
      }
    })
    labelSwiperRef.value.initialize()
  })
}

const timerAutoPlay = async (swiper) => {
  return new Promise(res => {
    setTimeout(() => {
      swiper.autoplay.start() // 播放完一轮后停止3秒,再播放
      res(true)
    }, 3000)
  })
}

watchEffect(()=>{
  if (typeof window != 'undefined' &&  props.config) {
    initSwiper()
  }
})
</script>
<style lang="less" scoped>
.labelSwiper-container{
    height: 20px;
}
.item-container{
    display: flex;
    color: #fff;
    height: 100%;
    align-items: center;
    font-size: 11px;
    .text{
        margin-left: 3px;
        color: #FFF;
        text-align: center;
        font-family: "SF Pro";
        .font-dpr(20px);
        font-style: normal;
        font-weight: 510;
        line-height: normal;
    }
    .font-ita{
      font-style: italic;
    }
}
</style>
